import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import { isEmpty, set } from "lodash";
import InfoCard from "src/components/Cards/InfoCard";
import DashboardButton from "src/components/Common/Button";
import OptionItem from "src/components/Common/OptionItem";
import Options from "src/components/Common/Options";
import TitleValue from "src/components/Common/TitleValue";
import toastr from "src/components/Common/toastr";
import AdvancedSettingsConfig from "src/containers/templateAdvancedSettingsConfig/AdvancedSettingsConfig";
import NewRoles from "src/containers/templateConfigRoles/NewRoles";
import LiveStreaming from "src/containers/templateLiveStreaming/LiveStreaming";
import RecordingConfig from "src/containers/templateRecordingConfig/RecordingConfig";
import Transcription from "src/containers/templateTranscription/Transcription";
import { getAppLayout } from "src/helpers/appLayoutHelper";
import { useCheckSubdomain } from "src/hooks/useCheckSubdomain";
import JoinRoomModal from "src/pages/Dashboard/JoinRoomModal";
import { getPublicTemplateFromFilter } from "src/services/cmsModels/Policy/helpers";
import { fetchAppLayout } from "src/store/appLayout/actions";
import { fetchShortCodeForAll } from "src/store/rooms/actions";
import {
  getEmailfromLocal,
  getInfoCard,
  isAdmin,
  setInfoCard,
} from "src/utils";
import {
  BrushDesignIcon,
  DoorIcon,
  GlobeIcon,
  GoLiveIcon,
  HorizontalMenuIcon,
  InfoIcon,
  JoinIcon,
  PencilDrawIcon,
  PeopleIcon,
  RecordIcon,
  SettingsIcon,
  StarIcon,
  TranscriptIcon,
  TrashIcon,
} from "@100mslive/react-icons";
import { Box, Button, Flex, Text, Tooltip } from "@100mslive/react-ui";
import { fetchPublicTemplates } from "../../actions/CreateAppActions";
import {
  CHECK_SUBDOMAIN_RESET,
  DELETE_POLICY_INFO_RESET,
  deletePolicyInfo,
  fetchPolicy,
  fetchPolicyInfo,
  fetchRolesData,
  setHasUnsavedLiveStreamingChanges,
  setHasUnsavedRecordingSettings,
  setHasUnsavedRoles,
  setHasUnsavedSettings,
  setHasUnsavedTranscriptionChanges,
  updateTemplate,
} from "../../actions/RolesActions";
import Credential from "../../components/Common/Credential";
import {
  AddSubdomainModal,
  DeleteTemplateModal,
  EditTemplateNameModal,
  MainDialog,
} from "../../components/Modal";
import {
  API_CALL_STATE,
  ROLE_TYPES,
  TEMPLATE_TABS,
  UNSAVED_CHANGES_TEXT,
  UPLOAD_TYPES,
} from "../../constants";
import {
  AppAnalytics,
  getJoinRoomToolTipTextConfigPage,
  getVisibleRoles,
  history,
  INTERNAL_RECORDER_ROLE,
} from "../../helpers";
import { useDefaultTemplate } from "../../hooks/template/useDefaultTemplate";
import { createErrorMessage } from "../../validations";

const iconStyle = { height: "18px", width: "18px" };

//eslint-disable-next-line complexity
export default function Template() {
  const { policyId } = useParams();
  const dispatch = useDispatch();
  const [isDefaultTemplate, updateDefaultTemplate] =
    useDefaultTemplate(policyId);
  const [editSubdomainDialogToggle, setEditSubdomainDialogToggle] =
    useState(false);
  const [addSubdomainDialogToggle, setAddSubdomainDialogToggle] =
    useState(false);
  const [checked, setChecked] = useState(false);
  const [openDeleteTemplate, setOpenDeleteTemplate] = useState(false);
  const [selected, setSelected] = useState(TEMPLATE_TABS.ROLES);
  const { publicTemplates } = useSelector(state => state.createApp);
  const internalRecorderExists = !!useSelector(
    state => state.roles?.policyInfo?.roles?.[INTERNAL_RECORDER_ROLE]?.name
  );

  const internalRecorderIsSubscribed =
    useSelector(
      state =>
        state.roles?.policyInfo?.roles?.[INTERNAL_RECORDER_ROLE]
          ?.subscribeParams?.subscribeToRoles || []
    ).length > 0;

  const isValidInternalRecorderState = internalRecorderExists
    ? internalRecorderIsSubscribed
    : true;

  const flexRef = useRef();
  // const [openRegion, setOpenRegion] = useState(false);
  const [openRoomLinks, setOpenRoomLinks] = useState(false);
  const {
    all_templates,
    templates,
    deleteRoleStatus,
    addRoleStatus,
    updateRolesStatus,
    hasUnsavedRoles,
    hasUnsavedRecordingSettings,
    fetchPolicyInfoStatus,
    policyInfo: originalInfo,
    deletePolicyStatus,
    updateTemplateStatus,
    hasUnsavedAdvancedSettings,
    hasUnsavedLiveStreamingChanges,
    hasUnsavedTranscriptionChanges,
    addSubdomainToApiTemplateStatus,
  } = useSelector(state => state.roles);
  const { createAppLayoutStatus, roleLayouts } = useSelector(
    state => state.appLayouts
  );

  const { current } = useSelector(state => state.workspace);
  const template = templates[policyId];
  const [triggerEditTemplate, setTriggerEditTemplate] = useState(false);
  const [policyInfo, setPolicyInfo] = useState({});
  const [showTemplateInfoCard, setShowTemplateInfoCard] = useState(false);
  const appType = useMemo(() => {
    if (publicTemplates.length > 0) {
      const cmsTemplate = getPublicTemplateFromFilter({
        filterKey: "id",
        filterValue: originalInfo?.cms_id,
        publicTemplates: publicTemplates,
      });
      if (isEmpty(cmsTemplate)) {
        return "CUSTOM";
      }
      return cmsTemplate?.appType;
    }
    return "CUSTOM";
  }, [originalInfo.cms_id, publicTemplates]);
  const navLinks = [
    {
      name: "Roles",
      icon: <PeopleIcon width={20} height={20} />,
      title: "roles",
    },
    {
      name: "Live Streaming",
      icon: <GoLiveIcon width={20} height={20} />,
      title: "live-streaming",
    },
    {
      name: "Recording",
      icon: <RecordIcon width={20} height={20} />,
      title: "recording",
    },
    {
      name: "Transcription",
      icon: <TranscriptIcon width={20} height={20} />,
      title: "transcription",
    },
    {
      name: "Advanced Settings",
      icon: <SettingsIcon width={20} height={20} />,
      title: "advanced",
    },
  ];
  const {
    error: subdomainError,
    status: checkSubdomainStatus,
    changeHandler: handleSubdomainNameChange,
  } = useCheckSubdomain({
    setSubdomain: subdomain => {
      const input = subdomain?.trim()?.toLowerCase();
      setPolicyInfo({
        ...policyInfo,
        subdomain: input + process.env.REACT_APP_VERCEL_BASE_DOMAIN,
        subdomain_name: input + process.env.REACT_APP_VERCEL_BASE_DOMAIN,
      });
    },
    subdomain: policyInfo?.subdomain?.split(".")[0],
  });
  const [isReadOnlyWorkspaceUser, setIsReadOnlyWorkspaceUser] = useState(true);

  useEffect(() => {
    let isReadOnlyUser = true;
    const currentMembers = current.members || [];
    const currentUserEmail = getEmailfromLocal();
    for (const member of currentMembers) {
      if (
        currentUserEmail === member?.email &&
        member?.role === ROLE_TYPES.READ_ONLY
      ) {
        isReadOnlyUser = true;
        break;
      } else {
        isReadOnlyUser = false;
      }
    }
    setIsReadOnlyWorkspaceUser(isReadOnlyUser);
  }, [current]);

  useEffect(() => {
    if (!publicTemplates.length) {
      dispatch(fetchPublicTemplates());
    }
    setShowTemplateInfoCard(getInfoCard("template-config"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // get the tab from URL query params
    if (typeof window !== "undefined" && useParams) {
      const params = new URLSearchParams(window.location.search);
      const pathName = window.location.pathname;
      const queryTab = params.get("tab");
      const selectedNavLink = navLinks.find(link => link.title === queryTab);
      if (selectedNavLink) {
        setSelected(selectedNavLink.name);
      } else {
        // if some value is provided in the tab param which does not exist in navLinks, default to the current value of "selected"
        const lastTab = navLinks.find(link => link.name === selected);
        window.history.replaceState(
          null,
          null,
          `${pathName}?tab=${lastTab.title}`
        );
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setHasUnsavedRoles(false));
    dispatch(setHasUnsavedSettings(false));
    dispatch(setHasUnsavedLiveStreamingChanges(false));
    dispatch(setHasUnsavedRecordingSettings(false));
    dispatch(setHasUnsavedTranscriptionChanges(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    return () => dispatch({ type: CHECK_SUBDOMAIN_RESET });
  }, [dispatch]);

  useEffect(() => {
    setPolicyInfo(template);
  }, [template]);

  useEffect(() => {
    if (fetchPolicyInfoStatus === API_CALL_STATE.FAILED) {
      history.push("/dashboard");
    }
  }, [fetchPolicyInfoStatus]);

  useEffect(() => {
    if (deletePolicyStatus === API_CALL_STATE.DONE) {
      if (all_templates.length === 0) {
        history.push("/");
      } else {
        let count = 0;
        all_templates.forEach((template, i) => {
          if (originalInfo.template_id === template.id) {
            count = i;
          }
        });
        if (count + 1 < all_templates.length) {
          history.push(`/templates/${all_templates[count + 1].id}`);
        } else {
          history.push(`/templates/${all_templates[0].id}`);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [all_templates, deletePolicyStatus]);

  useEffect(() => {
    return () => {
      if (deletePolicyStatus === "success") {
        dispatch({ type: DELETE_POLICY_INFO_RESET });
      }
    };
  });

  useEffect(() => {
    if (policyId) {
      dispatch(fetchPolicyInfo(policyId));
      dispatch(fetchAppLayout({ templateId: policyId }));
    }
  }, [policyId, dispatch]);

  useEffect(() => {
    if (!isEmpty(policyInfo) && !isEmpty(template)) {
      setPolicyInfo({
        ...policyInfo,
        subdomain_name: template?.subdomain_name || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);

  useEffect(() => {
    if (
      [
        addRoleStatus,
        deleteRoleStatus,
        updateRolesStatus,
        updateTemplateStatus,
      ].includes(API_CALL_STATE.DONE) &&
      policyId
    ) {
      dispatch(fetchRolesData(policyId));
      dispatch(fetchAppLayout({ templateId: policyId }));
      if (updateTemplateStatus === API_CALL_STATE.DONE) {
        dispatch(fetchPolicyInfo(policyId));
      }
    }
  }, [
    addRoleStatus,
    deleteRoleStatus,
    updateRolesStatus,
    dispatch,
    policyId,
    updateTemplateStatus,
  ]);

  const templateSubdomain =
    template?.subdomain_name || template?.subdomain || originalInfo?.subdomain;
  const originalInfoSubdomain =
    originalInfo?.subdomain_name || originalInfo?.subdomain;

  if (isEmpty(template)) {
    return (
      <div className="flex flex-column justify-center items-center mt-2 mb-4">
        <Spinner color="primary" />
      </div>
    );
  }
  const exportTemplate = async () => {
    try {
      const exportPolicyResp = await fetchPolicy(policyId);

      const exportRoleLayoutsResp = await getAppLayout({
        templateId: policyId,
      });
      if (
        !exportPolicyResp.data.success ||
        !exportRoleLayoutsResp.data.success ||
        isEmpty(exportPolicyResp.data.data)
      ) {
        throw new Error("Error exporting the template");
      }

      const exportPolicyRespData = exportPolicyResp.data.data;

      const uploadTypes = UPLOAD_TYPES.map(
        uploadType => uploadType.value
      ).filter(value => value !== "");

      if (
        !uploadTypes.includes(
          exportPolicyRespData?.settings?.recording?.upload?.type
        )
      ) {
        const hlsDestinations =
          exportPolicyRespData?.destinations?.hlsDestinations || {};
        const hlsDestinationIds = Object.keys(hlsDestinations) || [];
        const destinationId = hlsDestinationIds[0];

        // update only if an hls destination is present
        if (destinationId) {
          let updatedHLSDestination = { ...hlsDestinations[destinationId] };
          updatedHLSDestination = set(
            updatedHLSDestination,
            "recording.enableZipUpload",
            false
          );
          exportPolicyRespData["destinations"]["hlsDestinations"][
            destinationId
          ] = updatedHLSDestination;
        }
      }

      const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
        JSON.stringify(
          {
            ...exportPolicyRespData,
            role_layouts: { ...exportRoleLayoutsResp.data.data },
          },
          null,
          4
        )
      )}`;
      const link = document.createElement("a");
      link.href = jsonString;
      link.download = `${template.name}.json`;
      await link.click();

      toastr.success("Template exported successfully");
      AppAnalytics.track("export.template.successful", {
        templateId: policyId,
        data: jsonString,
        componentId: "template.configuration",
      });
    } catch (error) {
      console.debug(error);
      toastr.error("Error exporting the template");
    }
  };
  return (
    <>
      <div className="font-inter">
        <JoinRoomModal
          open={openRoomLinks}
          setOpen={setOpenRoomLinks}
          roomId={originalInfo?.room_id || ""}
          subdomain={originalInfoSubdomain}
          templateId={policyId}
          componentId="templateconfig"
        />

        <Flex justify="between" align="center">
          <Flex align="center" css={{ gap: "$space$4", w: "50%" }}>
            <Text
              variant="h5"
              css={{
                maxWidth: "100%",
                fontWeight: "$semiBold",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {template.name ? template.name : "Your Template"}
            </Text>

            <EditTemplateNameModal
              policyId={policyId}
              hideButton
              shouldFetchPolicy={true}
              trigger={triggerEditTemplate}
              setTrigger={setTriggerEditTemplate}
            />
            {isDefaultTemplate ? (
              <Tooltip
                title={
                  <Flex css={{ w: "$56" }} justify="start" align="start">
                    <Box css={{ h: "$8" }}>
                      <InfoIcon width={16} height={16} />
                    </Box>
                    <Text variant="caption" css={{ mt: "$1", ml: "$2" }}>
                      The default template acts as a fallback if you create a
                      room without a template ID (via Server Side APIs).
                    </Text>
                  </Flex>
                }
                side="bottom"
                align="center"
              >
                <Text
                  variant="overline"
                  css={{ bg: "$primaryDark", p: "$2 $4", r: "$0 " }}
                >
                  DEFAULT
                </Text>
              </Tooltip>
            ) : null}
          </Flex>
          <Flex ref={flexRef}>
            {originalInfo?.room_id ? (
              <Tooltip
                title={
                  <Text variant="sm">
                    {current?.room_code_disabled
                      ? "Enable room codes to view prebuilt"
                      : "No prebuilt attached to this template to customize"}
                  </Text>
                }
                disabled={
                  getVisibleRoles(Object.keys(roleLayouts || {})).length > 0 &&
                  getVisibleRoles(Object.keys(originalInfo?.roles || {}))
                    .length > 0 &&
                  originalInfoSubdomain &&
                  originalInfo?.room_enabled &&
                  !current?.room_code_disabled
                }
              >
                <Button
                  disabled={
                    getVisibleRoles(Object.keys(roleLayouts || {})).length <=
                      0 ||
                    !originalInfo.room_id ||
                    getVisibleRoles(Object.keys(originalInfo?.roles || {}))
                      .length <= 0 ||
                    !originalInfo?.room_enabled ||
                    !templateSubdomain ||
                    current?.room_code_disabled ||
                    isReadOnlyWorkspaceUser
                  }
                  loading={createAppLayoutStatus === API_CALL_STATE.IN_PROGRESS}
                  variant="standard"
                  onClick={() => {
                    dispatch(fetchShortCodeForAll(originalInfo?.room_id));
                    history.push(`/templates/${policyId}/prebuilt`);
                  }}
                  css={{
                    fontSize: "$sm",
                    h: "$12",
                    mr: "$6",
                    r: "$0",
                  }}
                  icon
                >
                  <BrushDesignIcon width={20} height={20} />
                  <Text variant="sub2">Customize Prebuilt</Text>
                </Button>
              </Tooltip>
            ) : null}
            <Tooltip
              title={getJoinRoomToolTipTextConfigPage({
                room_enabled: originalInfo?.room_enabled,
                room_id: originalInfo?.room_id,
                rolesLength: getVisibleRoles(
                  Object.keys(originalInfo?.roles || {})
                ).length,
                subdomain: originalInfoSubdomain,
                roomCodeDisabled: current?.room_code_disabled,
              })}
              disabled={
                getVisibleRoles(Object.keys(originalInfo?.roles || {})).length >
                  0 &&
                originalInfoSubdomain &&
                originalInfo?.room_enabled &&
                !current?.room_code_disabled &&
                originalInfo?.room_id
              }
            >
              <Button
                variant="primary"
                disabled={
                  isAdmin() ||
                  getVisibleRoles(Object.keys(originalInfo?.roles || {}))
                    .length === 0 ||
                  !originalInfoSubdomain ||
                  !originalInfo?.room_enabled ||
                  current?.room_code_disabled
                }
                onClick={() => {
                  setOpenRoomLinks(true);
                  AppAnalytics.track("btn.clicked", {
                    templateId: template.id,
                    templateName: template.name,
                    componentId: "templateConfigPage",
                  });
                }}
                css={{
                  fontSize: "$sm",
                  h: "$12",
                  mr: "$6",
                  r: "$0",
                }}
                icon
              >
                Join Room
              </Button>
            </Tooltip>
            <Options
              Icon={
                <Button
                  variant="standard"
                  outlined
                  css={{
                    fontSize: "$sm",
                    h: "$12",
                    w: "$12",
                    p: 0,
                    borderRadius: "$0",
                    color: "inherit",
                  }}
                >
                  <HorizontalMenuIcon
                    style={{ transform: "rotate(90deg)", color: "inherit" }}
                    size="30"
                  />
                </Button>
              }
              content={[
                {
                  data: (
                    <OptionItem
                      icon={<StarIcon style={iconStyle} />}
                      disabled={isDefaultTemplate}
                      title="Make Default"
                      onClick={() => {
                        if (!isDefaultTemplate) {
                          updateDefaultTemplate();
                        }
                      }}
                    />
                  ),
                },
                {
                  data: (
                    <OptionItem
                      link={`/rooms?template_id=${policyId}`}
                      icon={<DoorIcon style={iconStyle} />}
                      title="View All Rooms"
                    />
                  ),
                },
                {
                  data: (
                    <OptionItem
                      icon={
                        <JoinIcon
                          style={{ ...iconStyle, transform: "rotate(90deg)" }}
                        />
                      }
                      title="Export Template"
                      onClick={() => {
                        exportTemplate();
                      }}
                    />
                  ),
                },
                {
                  data: (
                    <OptionItem
                      onClick={() => setTriggerEditTemplate(true)}
                      icon={<PencilDrawIcon style={iconStyle} />}
                      title="Edit Template Name"
                    />
                  ),
                },
                {
                  data: (
                    <OptionItem
                      tooltipText="Subdomain does not exist"
                      disabled={!templateSubdomain}
                      onClick={() => setEditSubdomainDialogToggle(true)}
                      icon={<PencilDrawIcon style={iconStyle} />}
                      title="Edit Subdomain Name"
                    />
                  ),
                },
                {
                  data: (
                    <OptionItem
                      icon={<TrashIcon style={iconStyle} />}
                      disabled={isDefaultTemplate}
                      tooltipText="The default template cannot be deleted"
                      title="Delete Template"
                      onClick={() => {
                        if (!isDefaultTemplate) {
                          setOpenDeleteTemplate(!openDeleteTemplate);
                        }
                      }}
                    />
                  ),
                },
              ]}
            />
          </Flex>
        </Flex>
        <DeleteTemplateModal
          checked={checked}
          toggleCheckBox={() => {
            setChecked(!checked);
          }}
          disableButton={!checked}
          data={{
            title: template.name,
            subtitle: template.subdomain_name || template.subdomain,
          }}
          open={openDeleteTemplate}
          deleteFunction={() => {
            if (checked) {
              dispatch(deletePolicyInfo(originalInfo.template_id));
            }
          }}
          toggleDeleteDialog={() => {
            setOpenDeleteTemplate(state => !state);
          }}
        />

        <Flex
          css={{
            mt: "$14",
            w: "100%",
            mb: "$16",
            gap: "$12",
          }}
        >
          <Flex
            css={{
              border: "1px solid $borderDefault",
              borderRadius: "$1",
              w: "50%",
              p: "$12",
            }}
            align="center"
            justify="between"
          >
            <Flex direction="column">
              <Flex align="center">
                <Text
                  variant="sub1"
                  css={{ c: "$textHighEmp", fontWeight: 600 }}
                >
                  Prebuilt
                </Text>
              </Flex>
              <Text variant="body2" css={{ c: "$textMedEmp", fontWeight: 400 }}>
                Say hello to no-code. Customize and build your UI without
                leaving the dashboard
              </Text>
            </Flex>
            <Tooltip
              title={getJoinRoomToolTipTextConfigPage({
                room_enabled: originalInfo?.room_enabled,
                room_id: originalInfo?.room_id,
                rolesLength: getVisibleRoles(
                  Object.keys(originalInfo?.roles || {})
                ).length,
                subdomain: originalInfoSubdomain,
                roomCodeDisabled: current?.room_code_disabled,
              })}
              disabled={Boolean(
                getVisibleRoles(Object.keys(originalInfo?.roles || {})).length >
                  0 &&
                  originalInfoSubdomain &&
                  originalInfo?.room_enabled &&
                  !current?.room_code_disabled &&
                  originalInfo?.room_id
              )}
            >
              {!originalInfoSubdomain ? (
                <DashboardButton
                  loading={
                    addSubdomainToApiTemplateStatus ===
                    API_CALL_STATE.IN_PROGRESS
                  }
                  variant="highlight"
                  disabled={isReadOnlyWorkspaceUser}
                  css={{
                    r: "$0",
                    border: "0 !important",
                    ml: "$10",
                    transition: "all 0.4s ease",
                  }}
                  onClick={() => {
                    setAddSubdomainDialogToggle(true);
                  }}
                >
                  <Text variant="sub2">Add Prebuilt</Text>
                </DashboardButton>
              ) : (
                <DashboardButton
                  variant="highlight"
                  disabled={
                    getVisibleRoles(Object.keys(originalInfo?.roles || {}))
                      .length === 0 ||
                    !originalInfoSubdomain ||
                    !originalInfo?.room_enabled ||
                    current?.room_code_disabled ||
                    isReadOnlyWorkspaceUser
                  }
                  css={{
                    r: "$0",
                    border: "0 !important",
                    ml: "$10",
                    transition: "all 0.4s ease",
                  }}
                  onClick={() => {
                    setOpenRoomLinks(!openRoomLinks);
                    AppAnalytics.track("btn.clicked", {
                      templateId: template.id,
                      templateName: template.name,
                      componentId: "templateConfigPage",
                    });
                  }}
                >
                  <Text variant="sub2">Join via Prebuilt</Text>
                </DashboardButton>
              )}
            </Tooltip>
          </Flex>

          <Flex
            align="start"
            css={{
              border: "1px solid $borderDefault",
              borderRadius: "$1",
              w: "50%",
              p: "$12",
            }}
          >
            <Flex
              justify="between"
              css={{
                maxWidth: "800px",
                w: "100%",
                gap: "$4",
                flexWrap: "wrap",
              }}
            >
              <TitleValue
                title="Template ID"
                ellipsis
                textCss={{
                  fontFamily: "var(--user-font-roboto)",
                  fontWeight: 700,
                }}
                maxWidth="25vw"
                value={policyInfo?.id}
                link=""
                copyButton
              />

              <TitleValue
                title="Subdomain"
                ellipsis
                textCss={{
                  fontFamily: "var(--user-font-roboto)",
                  fontWeight: 700,
                }}
                maxWidth="25vw"
                copyButton={!!templateSubdomain || false}
                value={
                  templateSubdomain
                    ? templateSubdomain.split(".")?.[0]
                    : "Does not exist"
                }
                link=""
              />

              <Flex justify="start" direction="column">
                <TitleValue title="Region" value="" link="" />
                <Flex align="center" css={{ gap: "$4" }}>
                  <Text
                    variant="sm"
                    css={{ fontWeight: "$semiBold", color: "$textHighEmp" }}
                  >
                    {policyInfo?.settings?.region?.toUpperCase() || "AUTO"}
                  </Text>
                </Flex>
              </Flex>
            </Flex>

            <AddSubdomainModal
              policyId={policyId}
              trigger={addSubdomainDialogToggle}
              setTrigger={setAddSubdomainDialogToggle}
              appType={appType}
            />

            <MainDialog
              title="Edit Subdomain"
              variant="primary"
              Icon={<GlobeIcon />}
              disableButton={
                checkSubdomainStatus === API_CALL_STATE.IN_PROGRESS ||
                subdomainError
              }
              subtitle={
                <Text
                  variant="body1"
                  css={{
                    fontWeight: "$regular",
                    c: "$textMedEmp",
                    mb: "$12",
                  }}
                >
                  Change the subdomain you want associated with this template.
                </Text>
              }
              content={
                <>
                  <Credential
                    showClipboard={false}
                    css={{ w: "100%", mb: !subdomainError ? "$14" : "0" }}
                    error={subdomainError}
                    passEvent={true}
                    placeholder="e.g. mysubdomain"
                    success={
                      !(
                        checkSubdomainStatus === API_CALL_STATE.IN_PROGRESS ||
                        subdomainError
                      )
                    }
                    value={
                      policyInfo?.subdomain?.split(".")[0] ||
                      policyInfo?.subdomain_name?.split(".")[0]
                    }
                    label="Template Subdomain"
                    onChangeHandler={e => {
                      const input = e?.target?.value.trim()?.toLowerCase();
                      handleSubdomainNameChange(
                        input,
                        "subdomain.rename.success",
                        "edit.subdomain.template.config"
                      );
                    }}
                    loading={
                      checkSubdomainStatus === API_CALL_STATE.IN_PROGRESS
                    }
                    hideLabel
                    title=""
                    rightComp={
                      <Text
                        css={{
                          c: "$textAccentDisabled",
                        }}
                      >
                        {process.env.REACT_APP_VERCEL_BASE_DOMAIN}
                      </Text>
                    }
                  />
                  <Text
                    variant="caption"
                    css={{
                      fontWeight: "$regular",
                      marginTop: "$4",
                      c: "$error",
                    }}
                  >
                    {createErrorMessage(subdomainError)}
                  </Text>
                </>
              }
              open={editSubdomainDialogToggle}
              buttonText="Save"
              mainFunction={() => {
                dispatch(
                  updateTemplate({
                    policyId,
                    subdomain_name: policyInfo.subdomain_name,
                    analytics: {
                      event: "subdomain.edited",
                      success: {
                        templateId: template.id,
                        initialSubdomain: template.subdomain_name,
                        changedSubdomain: policyInfo.subdomain_name,
                      },
                      failed: {
                        templateId: template.id,
                        initialSubdomain: template.subdomain_name,
                        attemptedSubdomain: policyInfo.subdomain_name,
                      },
                    },
                  })
                );
                setEditSubdomainDialogToggle(false);
              }}
              onOpenChange={() => setEditSubdomainDialogToggle(prev => !prev)}
            />
          </Flex>
        </Flex>

        <InfoCard
          fullWidth
          btnText="Read More"
          open={showTemplateInfoCard}
          setOpen={() => {
            setShowTemplateInfoCard(false);
            setInfoCard("template-config", "closed");
          }}
          componentId="template.config"
          title="What are Templates?"
          body="A Template is the blueprint of a room. It defines the settings of a room along with the behavior of users within it."
          link={`${process.env.REACT_APP_WEBSITE_URL}docs/javascript/v2/foundation/templates-and-roles`}
        />
        <Flex
          css={{
            minWidth: "100%",
            borderBottom: "$space$px solid $borderLight",
            my: "$14",
          }}
        >
          {navLinks.map(navLink => {
            const isActive = selected === navLink.name;
            return (
              <Flex
                css={{
                  cursor: "pointer",
                  transition: "border-bottom-color 0.1s",
                  borderBottom: isActive
                    ? "2px solid $primaryLight"
                    : "2px solid transparent",
                  pb: "$4",
                  mr: "$14",
                }}
                onClick={() => {
                  if (
                    hasUnsavedRoles ||
                    hasUnsavedAdvancedSettings ||
                    hasUnsavedLiveStreamingChanges ||
                    hasUnsavedRecordingSettings ||
                    hasUnsavedTranscriptionChanges
                  ) {
                    if (!window.confirm(UNSAVED_CHANGES_TEXT)) {
                      return;
                    }
                  }
                  setSelected(navLink.name);
                  // replace URL with currently selected tab
                  const pathName = window.location.pathname;
                  if (useParams) {
                    window.history.replaceState(
                      null,
                      null,
                      `${pathName}?tab=${navLink.title}`
                    );
                  }
                }}
              >
                <Flex
                  justify="center"
                  align="center"
                  css={{
                    w: "$9",
                    h: "$9",
                    c: !isActive ? "$textMedEmp" : "$primaryLight",
                    mr: "$4",
                  }}
                >
                  {navLink.icon}
                </Flex>
                <Flex justify="start" align="center" gap="2">
                  <Text
                    variant="body2"
                    css={{
                      fontWeight: isActive ? "$semiBold" : "$regular",
                      c: isActive ? "$textHighEmp" : "$textMedEmp",
                    }}
                  >
                    {navLink.name}
                  </Text>
                  {navLink?.tag ? navLink?.tag : null}
                </Flex>
              </Flex>
            );
          })}
        </Flex>

        {selected === TEMPLATE_TABS.ROLES && <NewRoles policyId={policyId} />}
        {selected === TEMPLATE_TABS.LIVE_STREAMING && (
          <LiveStreaming
            policyId={policyId}
            setSelected={setSelected}
            isValidInternalRecorderState={isValidInternalRecorderState}
          />
        )}
        {selected === TEMPLATE_TABS.RECORDING && (
          <RecordingConfig
            policyId={policyId}
            isValidInternalRecorderState={isValidInternalRecorderState}
          />
        )}
        {selected === TEMPLATE_TABS.TRANSCRIPTION && (
          <Transcription policyId={policyId} />
        )}
        {selected === TEMPLATE_TABS.ADVANCED_SETTINGS && (
          <AdvancedSettingsConfig policyId={policyId} />
        )}
      </div>
    </>
  );
}
