import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { RootState } from "src/store/reducers";
import { Box, Flex } from "@100mslive/react-ui";
import PrebuiltHeaderButtons from "./PrebuiltHeaderButtons";
import PrebuiltSidebarContent from "./PrebuiltSidebarContent";
import { fetchPublicTemplates } from "../../actions/CreateAppActions";
import { fetchTemplatesData } from "../../actions/RolesActions";
import "../../pages/Dashboard/scroll.scss";
type PropTypes = {
  theme: any;
  type: any;
  isMobile: boolean;
};
type StateTypes = {};
type PathProps = {};
type ComponentStateType = StateTypes & RouteComponentProps<PathProps>;

class PrebuiltSidebar extends PureComponent<PropTypes, ComponentStateType> {
  constructor(props: PropTypes) {
    super(props);
    this.state = {} as ComponentStateType;
  }

  componentDidMount() {}

  public render() {
    return (
      <Box
        css={{
          backgroundColor: "$surfaceDark !important",
          borderRight: "1px solid $borderDefault",
          width: "20rem",
          transition: "width 0.3s ease",
        }}
        className="vertical-menu"
        id="left-vertical-menu"
      >
        <Flex
          align="center"
          css={{
            borderBottom: "1px solid $borderDefault",
            w: "100%",
            padding: "18px $10",
            overflow: "clip",
          }}
        >
          <PrebuiltHeaderButtons />
        </Flex>
        <div data-simplebar className="h-100 overflow-y-auto scroll">
          <PrebuiltSidebarContent />
        </div>
      </Box>
    );
  }
}

const mapStatetoProps = (state: RootState) => {
  return {
    layout: state.Layout,
    fetchTemplateStatus: state.roles.fetchTemplateStatus,
    all_templates: state.roles.all_templates,
    publicTemplates: state.createApp.publicTemplates,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    fetchTemplatesData,
    fetchPublicTemplates,
  })(PrebuiltSidebar) as any
);
